import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Home } from "./pages/Home"
import { Store } from "./pages/Store"
import { Gallery } from "./pages/Gallery"
import { Navbar } from "./components/Navbar"

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <div className="content">
         <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
          <Route path="/store" element={<Store/>}/>
         </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
